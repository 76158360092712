import * as React from 'react';
// import { Link } from 'gatsby';

import {
  AboutUs,
  Hero,
  Seo,
  Services,
  Portfolio,
  Inquiries
} from '../components/';

const IndexPage = () => (
  <>
    <Seo title="New York's Premier Real Estate Development Group" />
    <section aria-label="hero">
      <Hero />
    </section>
    <section aria-label="about" id="aboutSection">
      <AboutUs />
    </section>
    <section aria-label="services">
      <Services />
    </section>
    <section aria-label="portfolio" id="portfolioSection">
      <Portfolio />
    </section>
    <section
      aria-label="inquiries"
      id="inquiriesSection"
      style={{ filter: 'brightness(.9)' }}
    >
      <Inquiries />
    </section>
  </>
);

export default IndexPage;
